import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Router';

import Player from '../pages/Player';
import Main from '../pages/Main';

function Routes() {
    return (
        <Switch>
            <Route path="/" exact component={Main} />
            <Route path="/player" component={Player} isPrivate />
        </Switch>
    );
}

export default Routes;
