import React, { useEffect } from 'react';
import { Lottie, useLottie } from 'react-lottie-hook';
import animationData from '../../assets/live-loading-1.json';

import { useLive } from '../../providers/live';

function Main() {
    const { generateLiveUrl } = useLive();

    const urlParams = new URLSearchParams(decodeURI(window.location.search));
    const userToken =
        urlParams.get('userToken') ||
        sessionStorage.getItem('@ing_live:userToken');
    const ticketCode =
        urlParams.get('ticketCode') ||
        sessionStorage.getItem('@ing_live:ticketCode');
    const apikey =
        urlParams.get('apikey') ||
        sessionStorage.getItem('@ing_live:apikey') ||
        process.env.REACT_APP_ING_API_KEY;

    useEffect(() => {
        if (!userToken || !ticketCode) {
            return;
        }

        generateLiveUrl(userToken, ticketCode, apikey);
    }, [generateLiveUrl, userToken, ticketCode, apikey]);

    const [lottieRef] = useLottie({
        renderer: 'svg',
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
            progressiveLoad: false,
        },
        animationData,
    });

    if (!userToken || !ticketCode) {
        window.location.href = 'https://ingresse.com.br';

        return null;
    }

    return (
        <div className="main-page">
            <Lottie lottieRef={lottieRef} height={320} width={320} />
        </div>
    );
}

export default Main;
