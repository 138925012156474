import React, { useState, createContext, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';

const LiveContext = createContext({});

export const LiveProvider = ({ children }) => {
    const [data, setData] = useState({});

    const generateLiveUrl = useCallback(
        async (userToken, ticketCode, apikey) => {
            const urlParams = new URLSearchParams(
                decodeURI(window.location.search)
            );
            const env = urlParams.get('env');

            try {
                sessionStorage.setItem('@ing_live:userToken', userToken);
                sessionStorage.setItem('@ing_live:ticketCode', ticketCode);
                sessionStorage.setItem('@ing_live:apikey', apikey);

                const BASE_URL =
                    env && env === 'mock'
                        ? 'https://9013a249-1cca-4d44-aa30-a2ae9a3609d9.mock.pstmn.io'
                        : `https://${env ? `${env}-` : ''}api.ingresse.com`;

                const response = await fetch(
                    `${BASE_URL}/live?apikey=${apikey}&usertoken=${userToken}`,
                    {
                        headers: {
                            'x-mock-response-code': 200,
                            'Content-Type': 'application/json',
                        },
                        method: 'POST',
                        body: JSON.stringify({
                            code: ticketCode,
                        }),
                    }
                ).then((responseData) => responseData.json());

                if (
                    response &&
                    response.responseData &&
                    response.responseData.url
                ) {
                    const { url } = response.responseData;
                    const liveUrl = new URLSearchParams(
                        url.split('?')[1] || ''
                    );
                    const liveToken = liveUrl.get('jwt');

                    setData({
                        userToken,
                        ticketCode,
                        liveToken,
                        liveURL: url,
                    });
                }

                if (
                    response &&
                    response.responseError &&
                    response.responseError.message
                ) {
                    const { message } = response.responseError;
                    alert(message);
                }
            } catch (e) {
                alert('Erro ao gerar transmissão');
            }
        },
        []
    );

    return (
        <LiveContext.Provider
            value={{
                ...data,
                generateLiveUrl,
            }}
        >
            {children}
        </LiveContext.Provider>
    );
};

LiveProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export function useLive() {
    const context = useContext(LiveContext);

    if (!context) {
        throw new Error();
    }

    return context;
}
