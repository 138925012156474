import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';

import AppProvider from './providers';

import './styles/global.css';
import './styles/main-page.css';

function App() {
    return (
        <AppProvider>
            <BrowserRouter>
                <Routes />
            </BrowserRouter>
        </AppProvider>
    );
}

export default App;
