import React from 'react';

import { useLive } from '../../providers/live';

function Player() {
    const { liveURL } = useLive();

    return (
        <iframe
            title="Player"
            allowFullScreen="yes"
            frameBorder="0"
            scrolling="yes"
            src={liveURL}
        />
    );
}

export default Player;
