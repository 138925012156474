import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

import { useLive } from '../providers/live';

function RouteWrapper({
    component: Component,
    isPrivate,

    ...rest
}) {
    const { liveToken } = useLive();
    const signed = !!liveToken;
    const urlParams = new URLSearchParams(decodeURI(window.location.search));
    const env = urlParams.get('env');

    if (!signed && isPrivate) {
        return <Redirect to={{ pathname: '/', search: env && `env=${env}` }} />;
    }

    if (signed && !isPrivate) {
        return (
            <Redirect
                to={{ pathname: '/player', search: env && `env=${env}` }}
            />
        );
    }

    return <Route {...rest} render={(props) => <Component {...props} />} />;
}

RouteWrapper.propTypes = {
    isPrivate: PropTypes.bool,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
        .isRequired,
};

RouteWrapper.defaultProps = {
    isPrivate: false,
};

export default RouteWrapper;
