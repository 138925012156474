import React from 'react';
import PropTypes from 'prop-types';

import { LiveProvider } from './live';

function AppProvider({ children }) {
    return <LiveProvider>{children}</LiveProvider>;
}

AppProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default AppProvider;
